<template>
  <v-app class="home-layout">
    <div class="home-layout__background" />
    <v-main class="home-layout__main">
      <v-container class="fill-height justify-center">
        <NuxtPage />
      </v-container>
    </v-main>

    <Snackbar />
    <MemberDock />
    <TheDialog />
  </v-app>
</template>
<script lang="ts">
import { mapMutations } from '~/assets/javascript/modules/vuex';
import MemberDock from '~/components/docks/member';
import Snackbar from '~/components/Snackbar';
import TheDialog from '~/components/dialogs/the-dialog';

export default {
  name: 'HomeLayout',
  components: {
    Snackbar,
    TheDialog,
    MemberDock,
  },
  setup() {
    useWorkspace();
    buildHead();

    return {
      ...mapMutations('environment', ['setCurrentEnvironment']),
      ...mapMutations('studio', ['setOutsideStudio']),
    };
  },
  beforeMount() {
    this.setOutsideStudio();
    this.setCurrentEnvironment('member');
  },
  mounted() {
    const utmsObject = _pickBy(this.$route.query, (_value, key) => _startsWith(key, 'utm_'));

    if (!_isEmpty(utmsObject)) {
      window.analytics.track('referredFrom', utmsObject);
    }
  },
};
</script>
<style lang="scss">
.home-layout__main .v-container {
  padding-top: var(--z-s8);
}

// we are using a custom launcher for intercom, so we need to hide the default one
.intercom-messenger-frame {
  bottom: var(--z-s4) !important;
  right: var(--z-s4) !important;
}

.intercom-lightweight-app-launcher,
.intercom-launcher {
  display: none !important;
}

.intercom-app {
  &>div:nth-child(2) {
    display: none !important;
  }
}
</style>
